import { useTracker } from '@/core/tracking';
import { useUploaderService } from '@/layout/appWrapper/ServiceProvider';
import checkAccentAltIcon from '@/public/images/icons/check--accent-alt.svg';
import dropIcon from '@/public/images/icons/drop.svg';
import errorIcon from '@/public/images/icons/error.svg';
import { useLocalization } from '@/services/localizationService';
import { TaskError, TaskStatus, TaskType } from '@/services/uploaderService';
import { previousMediaTypeState } from '@/stores/previousMediaTypeStore';
import taskStateFamily from '@/stores/taskStateFamilyStore';
import { ErrorAlert } from '@/ui/content';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { enhanceFlowTracker } from './trackers';

type Props = {
  forId?: string;
  className?: string;
  mediaType: string;
  isBulkUpload?: boolean;
  taskId?: string;
};

export default function Progress({
  forId,
  className = '',
  mediaType,
  isBulkUpload,
  ...rest
}: Props) {
  const task = useRecoilValue(taskStateFamily(forId));
  const resetTask = useResetRecoilState(taskStateFamily(forId));
  const { track } = useTracker();
  const router = useRouter();
  const { t, data } = useLocalization();
  const [fileProgress, setFileProgress] = useState<string | undefined>('');
  const { cancelVideoTask } = useUploaderService();
  const previousMediaType = useRecoilValue(previousMediaTypeState);

  const isVideoTask =
    mediaType?.includes(TaskType.Video) &&
    router.query.mediaType === TaskType.Video;

  useEffect(() => {
    if (task.status === TaskStatus.Uploading) {
      setFileProgress(task.fileProgress);
    }
  }, [task]);

  return (
    <div
      className={`flex w-full h-full items-center justify-center section-foreground ${className} relative`}
      {...rest}
    >
      {(() => {
        switch (task.status) {
          case 'draft':
            return (
              <div className="space-y-6 text-center leading-none">
                <img {...dropIcon} className="inline-block" alt="" />
                <h3 className="text-black SB25">
                  {t(data.processing.dropFile)}
                </h3>
              </div>
            );
          case TaskStatus.Invalid:
            return (
              <ErrorAlert highlight title="Invalid upload">
                <div className="space-y-3">
                  <p>
                    {(() => {
                      switch (task.reason) {
                        case TaskError.TooFewFiles:
                          return <>{t(data.processing.tooFewFiles)}</>;
                        case TaskError.TooManyFiles:
                          return <>{t(data.processing.tooManyFiles)}</>;
                        case TaskError.WrongFileFormat:
                          return <>{t(data.processing.wrongFileFormat)}</>;
                        default:
                          return <>{t(data.processing.unknownError)}</>;
                      }
                    })()}
                  </p>
                  <button
                    type="button"
                    className="btn btn--large btn--primary mx-auto"
                    onClick={resetTask}
                  >
                    {t(data.common.gotIt)}
                  </button>
                </div>
              </ErrorAlert>
            );
          case TaskStatus.Uploading:
            return (
              <div className="space-y-8">
                {!isVideoTask && <p className="text-center">{fileProgress}</p>}
                <div
                  className="h-1 rounded bg-black w-40 mx-auto"
                  role="progressbar"
                  aria-valuenow={task.progress}
                  aria-valuemin={0}
                  aria-valuemax={100}
                >
                  <div
                    className="h-full rounded bg-primary-accent transition-all"
                    style={{ width: `${task.progress}%` }}
                  />
                </div>
                <div className="space-y-3 text-center max-w-xs">
                  <h3 className="text-black SB25">
                    {t(data.processing.uploadingFiles)}
                  </h3>
                  <p className="M15 text-overlay-black-60">
                    {t(data.processing.uploadingFileSubtitle)}
                  </p>
                </div>
              </div>
            );
          case TaskStatus.Processing:
            return (
              // TODO: add spinner
              <div className="space-y-8 relative">
                <div className="space-y-3 text-center max-w-xs relative">
                  <h3 className="text-black SB25">
                    {t(data.processing.enhancingFiles)}
                  </h3>
                  {isVideoTask ? (
                    <p className="M15 text-overlay-black-60">
                      {t(data.processing.enhancingFileSubtitle)}
                    </p>
                  ) : isBulkUpload ? (
                    <p className="M15 text-overlay-black-60">
                      {t(data.processing.enhancingBulkUploadSubtitle)}
                    </p>
                  ) : (
                    <p className="M15 text-overlay-black-60">
                      {t(data.processing.uploadingFileSubtitle)}
                    </p>
                  )}
                </div>
                {isVideoTask && (
                  <button
                    onClick={async () => {
                      await cancelVideoTask(task.id!);
                      track(TaskStatus.Processing, TaskStatus.Cancelled, {
                        actionValue: 'cancel',
                        mediaType: TaskType.Video,
                      });
                      enhanceFlowTracker.cancelProcess();
                    }}
                    className="btn btn--large btn-secondary px-7 pe-8 rounded-full m-auto absolute top-60 left-2/4 -translate-x-2/4 whitespace-nowrap"
                  >
                    <img
                      className="inline-block w-8 h-8 me-2"
                      {...errorIcon}
                      alt=""
                    />
                    {t(data.processing.cancelEnhancement)}
                  </button>
                )}
              </div>
            );
          case TaskStatus.Failed:
            return (
              <div className="space-y-6 text-center leading-none">
                <ErrorAlert highlight title="Enhancement failed">
                  <div className="space-y-3">
                    <p>{t(data.processing.unknownError)}</p>
                    <button
                      type="button"
                      className="btn btn--large btn--primary mx-auto"
                      onClick={() => {
                        resetTask();
                        if (previousMediaType) {
                          router.replace(
                            {
                              query: {
                                ...router.query,
                                mediaType: previousMediaType,
                              },
                            },
                            undefined,
                            { shallow: true }
                          );
                        }
                      }}
                    >
                      {t(data.common.retry)}
                    </button>
                  </div>
                </ErrorAlert>
              </div>
            );
          case TaskStatus.Completed:
            return (
              <div className="space-y-6 text-center leading-none">
                <img {...checkAccentAltIcon} className="inline-block" alt="" />
                <h3 className="text-black SB25">
                  {isVideoTask
                    ? t(data.processing.videoEnhancedSuccess)
                    : t(data.processing.imageEnhancedSuccess)}
                </h3>
              </div>
            );
          default:
            return null;
        }
      })()}
    </div>
  );
}
