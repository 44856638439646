import { animate, useMotionValue } from 'framer-motion';
import dynamic from 'next/dynamic';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import useEventListener from '@/core/listeners/useEventListener';
import { clamp } from '../../core/math/math';
import { Vector } from './vector';

type Props = {
  beforeSrc: string;
  afterSrc: string;
  backgroundColor: number;
  badgesAlignment?: string;
  minSliderProgress?: number;
  maxSliderProgress?: number;
  initialSliderProgress?: number;
  initialFit: string;
  disablePan?: boolean;
  disablePinch?: boolean;
  onPan?: () => void;
  onZoom?: () => void;
  onSlide?: () => void;
  sliderBehavior: string;
  hjSuppress?: boolean;
  showTooltip?: boolean;
  isLoading?: boolean;
  className?: string;
  onLoadComplete?: () => void;
  onLoadStart?: () => void;
  onLoadFail?: (e: unknown) => void;
};

const Canvas = dynamic(() => import('./Canvas'), {
  ssr: false,
});
const Slider = dynamic(() => import('./Slider'), {
  ssr: false,
});

export default function Comparer({
  beforeSrc,
  afterSrc,
  backgroundColor,
  badgesAlignment,
  minSliderProgress = 0,
  maxSliderProgress = 1,
  initialSliderProgress = 0.5,
  initialFit,
  disablePan,
  disablePinch,
  onPan,
  onZoom,
  onSlide,
  sliderBehavior,
  hjSuppress,
  showTooltip = false,
  isLoading = false,
  className,
  onLoadStart,
  onLoadComplete,
  onLoadFail,
  ...rest
}: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerSize, setContainerSize] = useState(new Vector());

  const updateContainerSize = useCallback(() => {
    const el = containerRef.current;
    const containerSize = new Vector(el!.clientWidth, el?.clientHeight);
    setContainerSize(containerSize);
    return containerSize;
  }, []);
  useEventListener(null, 'resize', updateContainerSize);

  const [prevInitialSliderProgress, setPrevInitialSliderProgress] =
    useState(-1);
  const [sliderProgress, setSliderProgress] = useState(0);
  const [sliderOpacity, setSliderOpacity] = useState(0);

  const animatedSliderProgress = useMotionValue(0);

  useEffect(() => {
    if (prevInitialSliderProgress !== initialSliderProgress) {
      animate(animatedSliderProgress, initialSliderProgress, {
        delay: 1,
        type: 'spring',
        bounce: 0.2,
        duration: 1.5,
      });
      setPrevInitialSliderProgress(initialSliderProgress);
    }

    const onChange = (n: number) => {
      setSliderOpacity(n / initialSliderProgress);
      setSliderProgress(n);
    };

    const unsubscribe = animatedSliderProgress.onChange(onChange);
    return unsubscribe;
  }, [
    prevInitialSliderProgress,
    animatedSliderProgress,
    initialSliderProgress,
  ]);

  const preventSwipeOnEdges = useCallback((e) => {
    const { pageX } = e.touches[0];
    // If near the edge of view, prevent swipe gesture
    if (pageX <= 60 || pageX >= window.innerWidth - 60) e.preventDefault();
  }, []);

  const touchStartOptions = useMemo(() => ({ passive: false }), []);

  useEventListener(
    containerRef,
    'touchstart',
    preventSwipeOnEdges,
    touchStartOptions
  );

  // Run once right after rendering
  useEffect(() => {
    updateContainerSize();
  }, [updateContainerSize]);

  const onSliderPan = useCallback(
    (offset: number) => {
      const normalizedOffset = clamp(offset, 16, containerSize.width - 16);
      setSliderProgress(
        clamp(
          normalizedOffset / containerSize.width,
          minSliderProgress,
          maxSliderProgress
        )
      );
      if (typeof onSlide === 'function') {
        onSlide();
      }
    },
    [containerSize, minSliderProgress, maxSliderProgress, onSlide]
  );

  return (
    <div
      className={`relative overflow-hidden w-[100%] h-[100%] ${className}`}
      {...rest}
      style={{
        backgroundColor: `#${backgroundColor.toString(16)}`,
        touchAction: 'none',
      }}
      ref={containerRef}
    >
      {isLoading && (
        <div className="absolute z-[100] w-[100%] h-[100%] flex justify-center items-center frosted">
          {isLoading && (
            <TailSpin
              color="white"
              height="80"
              width="80"
              aria-label="loading"
            />
          )}
        </div>
      )}
      <Canvas
        beforeSrc={beforeSrc}
        afterSrc={afterSrc}
        className="absolute inset-0"
        canvasSize={containerSize}
        initialFit={initialFit}
        disablePan={disablePan}
        disablePinch={disablePinch}
        backgroundColor={backgroundColor}
        trim={sliderProgress * containerSize.width}
        onPan={onPan}
        onZoom={onZoom}
        data-hj-suppress={hjSuppress}
        onLoadStart={onLoadStart}
        onLoadComplete={onLoadComplete}
        onLoadFail={onLoadFail}
      />
      <Slider
        offset={sliderProgress * containerSize.width}
        badgesAlignment={badgesAlignment}
        borderWidth={2}
        onChange={onSliderPan}
        style={{ opacity: sliderOpacity }}
        sliderBehavior={sliderBehavior}
        showTooltip={showTooltip}
      />
    </div>
  );
}
