import { atomFamily, selectorFamily, SerializableParam } from 'recoil';
import { clamp } from '../../core/math/math';
import { MAX_ZOOM, MIN_ZOOM } from './constants';

const internalScaleStateFamily = atomFamily<number, SerializableParam>({
  key: 'comparer:internalScale',
  default: 1,
});

const scaleSelectorFamily = selectorFamily<number, SerializableParam>({
  key: 'comparer:scale',
  get:
    (key) =>
    ({ get }) =>
      get(internalScaleStateFamily(key)),
  set:
    (key) =>
    ({ set }, value) =>
      set(
        internalScaleStateFamily(key),
        clamp(value as number, MIN_ZOOM, MAX_ZOOM)
      ),
});

export default scaleSelectorFamily;
