import '@pixi/math-extras';
import { Point } from '@pixi/math';
import { clamp } from '../../core/math/math';

export class Vector extends Point {
  // eslint-disable-next-line no-use-before-define
  static ZERO: Vector;

  constructor(x?: number, y?: number) {
    if (typeof y === 'undefined') {
      super(x, x);
    } else {
      super(x, y);
    }
  }

  /**
   * @override
   */
  add(v: Vector | Point) {
    const { x, y } = super.add(v);
    return new Vector(x, y);
  }

  /**
   * @override
   */
  subtract(v: Vector | Point) {
    const { x, y } = super.subtract(v);
    return new Vector(x, y);
  }

  /**
   * @override
   */
  multiplyScalar(n: number) {
    const { x, y } = super.multiplyScalar(n);
    return new Vector(x, y);
  }

  copyFrom(o: any) {
    const { x, y, width, height } = o;
    this.x = typeof x === 'number' ? x : width;
    this.y = typeof y === 'number' ? y : height;
    return this;
  }

  divide(v: Vector) {
    return new Vector(this.x / v.x, this.y / v.y);
  }

  /**
   * Return the biggest component
   */
  max() {
    const { x, y } = this;
    return Math.max(x, y);
  }

  /**
   * Return the smallest component
   */
  min() {
    const { x, y } = this;
    return Math.min(x, y);
  }

  /**
   * Turn into a strictly positive vector
   */
  abs() {
    const { x, y } = this;
    return new Vector(Math.abs(x), Math.abs(y));
  }

  clamp(min: Vector | number, max: Vector | number) {
    const minV = typeof min === 'number' ? new Vector(min) : min;
    const maxV = typeof max === 'number' ? new Vector(max) : max;

    const { x, y } = this;
    const { x: minX, y: minY } = minV;
    const { x: maxX, y: maxY } = maxV;

    return new Vector(clamp(x, minX, maxX), clamp(y, minY, maxY));
  }

  get width() {
    return this.x;
  }

  get height() {
    return this.y;
  }
}

Vector.ZERO = new Vector(0);
