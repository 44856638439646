import { useResetRecoilState, useSetRecoilState } from 'recoil';
import LinkWithQuery from '@/ui/content/LinkWithQuery';

import logo from '@/public/images/logo.svg';
import logoMobile from '@/public/images/logo-mobile.svg';
import React from 'react';
import taskStateFamily from '@/stores/taskStateFamilyStore';
import { TaskStatus } from '@/services/uploaderService';
import { downloadMediaState } from '@/stores/mediaStore';

function Header({
  className = '',
  children,
  canClickToHomePage = true,
}: {
  className?: string;
  children?: React.ReactNode;
  canClickToHomePage?: boolean;
}) {
  const setTask = useSetRecoilState(taskStateFamily('home'));
  const resetMediaToDownload = useResetRecoilState(downloadMediaState);

  return (
    <header
      className={`container-full flex py-2 justify-between items-center ${className}`}
    >
      <div className="flex flex-1 sm:me-4">
        {canClickToHomePage ? (
          <LinkWithQuery path="/">
            <a
              onClick={() => {
                resetMediaToDownload();
                setTask({ status: TaskStatus.Pristine });
              }}
              className="leading-none py-4"
            >
              <img {...logo} className="hidden md:block" alt="Remini Web" />
              <img {...logoMobile} className="md:hidden" alt="Remini Web" />
            </a>
          </LinkWithQuery>
        ) : (
          <div className="leading-none py-4">
            <img {...logo} className="hidden md:block" alt="Remini Web" />
            <img {...logoMobile} className="md:hidden" alt="Remini Web" />
          </div>
        )}
      </div>
      <div className="flex items-center space-s-4 md:space-s-8">{children}</div>
    </header>
  );
}

Header.Divider = function HeaderDivider({
  className = '',
}: {
  className: string;
}) {
  return (
    <hr
      className={`w-0 h-5 contrast-lower sm:border-r border-current ${className}`}
    />
  );
};

export default Header;
